import React from 'react'
import Layout from "../components/Layout";
import Showcase from "../components/Showcase";

import Cta from "../components/Cta";
import ServicesBg from "../images/services.png"

import Infographic from "../components/Infographic";

import WorksEverywhere from "../images/works-everywhere.png"
import Kilogram from "../images/kilogram.png"
import HollowKnight from "../images/hollow-knight-ipad.png"
import SuperseedStudios from "../images/superseed-studios-laptop.png"
// import OverwatchLeague from "../images/overwatchleague.png"
// import PlayOverwatch from "../images/playoverwatch.png"
import SDLC from "../images/SDLC.png"
import River from "../images/river.png"
import BnetShop from "../images/bnet-shop.png"
import HFP from "../images/hfp.png"

function Portfolio() {
  return (
    <Layout>
      <Infographic 
        paddingTop="5em"
        paddingBottom="6em"
        image={WorksEverywhere} 
        title="our work, works everywhere." 
        titleStyle={{ paddingTop: '0' }}
        description="see samples of our diverse portfolio of work from websites to web apps to mobile apps." 
        maxWidth={'24em'}
      >
      </Infographic>

      <hr className="divider" />

      <Showcase 
        reverse={true}
        type={'phone'}
        color={''}
        textColor={'dark'}
        title={'Kilogram'}
        image={Kilogram}
        tags={['social', 'photography', 'modern', 'web & mobile']}
        description={[
          'Our client wanted to create a photography microblogging social platform. They needed help designing a mobile application that properly represented their vision and would appeal to a young creative audience.',
          'We helped them solidify their ideas into a sleek and modern iOS application and extensively user tested the prototypes amongst the targeted 18-35 audience in order to guarantee a stellar user experience. We delivered a design that our client was ecstatic to receive.'
        ]}
      />
      <Showcase 
        reverse={false}
        type={'tablet'}
        color={''}
        textColor={'dark'}
        title={'Hollow Knight'}
        image={HollowKnight}
        link={'https://hungry-kilby-bf32ff.netlify.com/'}
        tags={['gaming', 'website', 'digital sales']}
        caseStudylink={'/case-studies/hollow-knight'}
        description={[
          'Hollow Knight is a successful independently developed video game for multiple gaming platforms including Playstation 4, Xbox One, Nintendo Switch, PC, and more. We rebuilt and redesigned their website, improving sales conversion.', 
          'We worked with a quick deadline: 2 weeks to redesign and rebuild their website on a modern technology stack, improve usability and sales conversion, and allow for non-developer to edit site content. We knocked it out of the park and delivered a beautiful new website that hit all our goals in just 10 days.'
        ]}
      />
      <Showcase 
        reverse={true}
        type={'laptop'}
        color={''}
        textColor={'dark'}
        title={'Superseed Studios'}
        link={'https://superseedstudios.com'}
        image={SuperseedStudios}
        tags={['animation', 'studio', 'website']}
        description={[
          'Superseed Studios is a premier remote animation studio that serves large video game companies and media corporations in providing gameplay and cinematic animations.',
          'We created a consistent brand across their company and built a website that attracts large clients to their studio by clearly communicating their company messaging and showcasing highlights of their past work.'
        ]}
      />
      <Showcase 
        reverse={false}
        type={'tablet'}
        color={''}
        textColor={'dark'}
        title={'SDLC'}
        image={SDLC}
        tags={['maps', 'dashboard', 'webapp']}
        description={[
          'We developed a responsive web application for a company that provides in home life coaching and consultation services. They needed a modern system to keep track of their growing list of clientele.', 
          'We built a scalable solution that allowed them to view and manage their clients on dashboard and overlay client locations a map. We also improved their efficiency with a feature that allowed them to split their clientele among employees based on distance, saving them time and money.'
        ]}
      />
      {/*<Showcase 
        reverse={true}
        type={'laptop'}
        color={''}
        textColor={'dark'}
        title={'Playoverwatch'}
        link={'https://playoverwatch.com'}
        image={PlayOverwatch}
        tags={['gaming', 'official', 'marketing', 'website']}
        description={[
          'As a full time Software Engineer at Blizzard Entertainment I built the front end/user facing portions of the official website for the award winning game Overwatch.',
          'It was done securely on a short timeline in a high expectation environment. Any leaks would have been disastrous to the company and we had to scale it for the launch of the game, which meant potentially millions of hits per minute. The team delivered on all fronts and we launched a beautiful website.'
        ]}
      />*/}
      <Showcase 
        reverse={false}
        type={'phone'}
        color={''}
        textColor={'dark'}
        title={'River'}
        image={River}
        tags={['music', 'social', 'modern', 'web & mobile']}
        description={[
          'A collaborative playlist application built on top of the Spotify API. Our charter was to design and create a more communal way of listening to music, as well as creating and sharing playlists.',
          'We designed this clean and feature rich iOS application that our client was thrilled by. It was praised as beautiful, modern, easy to use, and strongly appealed to our client’s core audience demographic.'
        ]}
      />
      <Showcase 
        reverse={true}
        type={'laptop'}
        color={''}
        textColor={'dark'}
        title={'Financial Planning'}
        link={'https://www.holistic-financial-planning.com/'}
        image={HFP}
        tags={['finance', 'finra', 'business', 'website']}
        description={[
          "We redesigned the website and branding for a financial planning firm in Irvine California with the goal of improving lead generation and clearly presenting company messaging.",
          'We also helped them cut down on their web hosting costs by 300% by getting rid of an unnecessary subscription CMS and freed up their staff to focus on their core business instead of trying to juggle and maintain a DIY website.'
        ]}
      />

    <hr className="divider" />

    <Cta title="see what we can do for you" buttonText="services" link="/services" bg={ServicesBg}/>
  </Layout>


  )
}

export default Portfolio;
